// @import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url("https://use.typekit.net/vxo7nwa.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$pri-color: #1a1a1a;
$sec-color: #FFC107;

$pri-text-color: #1a1a1a;
$sec-text-color: #FFC107;
$bg-pri-color: #F9F9F9;
$bg-sec-color: #1a1a1a;
$bg-sec-white: #ffffff;

$sec-textpara-size: 1rem; //16px
$sec-sideheading-size: 1.125rem; ///18px
$sec-20px-size: 1.25rem;
$sec-smallheading-text-size: 1.5rem; //24px
$sec-bigheading-text-size: 2.5rem; //38px

$font-thin: 100;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;


@mixin button($bcolor, $url, $x1, $y1, $bor, $col) {
	background: $bcolor;
	-webkit-mask: url($url);
	mask: url($url);
	-webkit-mask-size: $x1 $y1;
	mask-size: $x1 $y1;
	border: $bor;
	color: $col;
}

@mixin quinn-text-light() 
{font-family: quinn-text, serif;
font-weight: 300;
font-style: normal;
}

@mixin quinn-text-regular() {
  font-family: "quinn-text", serif;
  font-weight: 400;
  font-style: normal;
}

@mixin quinn-text-semibold() 
{
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
}

@mixin quinn-text-bold() {
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
}

@mixin quinn-text-extrabold()
{
  font-family: quinn-text, serif;
  font-weight: 800;
  font-style: normal;
}

* 
{
    scroll-behavior: smooth;
}

html 
{
    overflow-x: hidden;
}

  body
{
  font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    margin: 0px;
    padding: 0px;
    color: #1D1D1D;
    background-color: $bg-sec-white;
    box-sizing: border-box;
    line-height: 25px !important;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #fafafa; 
  }
   
  ::-webkit-scrollbar-thumb {
    background: $pri-color; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: $sec-color; 
  }

  .logo
  {
    width: 90px;
    object-fit: fill;
  }
 
  p 
  {
    text-align: left;
  }

  a 
  {
    color: $bg-sec-color; 
    text-underline-position: under
  }

  .mini-header
  {
    display: block;
    padding: 10px;
    background-color: $bg-pri-color;

    .mini-header-divs
    {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;

      .contact-sec
      {
        display: flex;
        flex-wrap: nowrap;
        
        div{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding: 0px 15px 0px 0px;
          a
          {
            color: $pri-text-color;
            font-weight: $font-medium;
            text-decoration: none;
            font-size: 14px;
            transition: 0.4s ease-out;
          }

          &:hover 
          {
            text-decoration: underline;
            text-underline-position: under;
          }
        }
      }
    }

    svg
    {
      width: 20px;
      height: 20px;
      fill: $pri-text-color;
      margin: 0px 10px 0px 0px;
      transition: 0.2s;
    }
  }

  .navbar
  {
    background-color: $bg-sec-white;

    .navbar-brand
    {
      padding: 0px;
    }
  }

  .navbar-expand-lg .navbar-nav .nav-link
    {
      font-weight: $font-semibold;
      padding: 0.5rem 1rem;
    }

    .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
      text-decoration: underline;
      text-underline-position: under;
      font-weight: $font-bold;
  }

  // .btn
  // {
  //   background: $bg-sec-color;
  //   color: #fff;
  //   border-radius: 0px;
  //   padding: 0.5rem 2rem !important;
  //   border: 1px solid $bg-sec-color;
  //   overflow: hidden;
  //   position: relative;

  //   @include button(
	// 		#000,
	// 		"https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png",
	// 		3000%,
	// 		100%,
	// 		none,
	// 		#fff
	// 	);
	// 	cursor: pointer;
	// 	-webkit-animation: ani2 0.7s steps(29) forwards;
	// 	animation: ani2 0.7s steps(29) forwards;

	// 	&:hover {
	// 		-webkit-animation: ani 0.7s steps(29) forwards;
	// 		animation: ani 0.7s steps(29) forwards;
  //     // border: 1px solid $bg-sec-color;
  //     // color: $bg-sec-color;
  //     // background-color: $bg-sec-white;
	// 	}

  //   @keyframes ani {
  //     from {
  //       -webkit-mask-position: 0 0;
  //       mask-position: 0 0;
  //     }
    
  //     to {
  //       -webkit-mask-position: 100% 0;
  //       mask-position: 100% 0;
  //     }
  //   }
    
  //   @keyframes ani2 {
  //     from {
  //       -webkit-mask-position: 100% 0;
  //       mask-position: 100% 0;
  //     }
    
  //     to {
  //       -webkit-mask-position: 0 0;
  //       mask-position: 0 0;
  //     }
  //   }

  // }

  .button-container {
    position: relative;
    overflow: hidden;
    border: 1px solid #000;
    transition: 0.5s;
    width: 170px;

    .nav-link
    {
      border-radius: 0px;
    }

    .btn {
      height: 100%;
  
      @include button(
        #000,
        "https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png",
        3000%,
        100%,
        none,
        #fff
      );
      cursor: pointer;
      -webkit-animation: ani2 0.7s steps(29) forwards;
      animation: ani2 0.7s steps(29) forwards;
  
      &:hover {
        -webkit-animation: ani 0.7s steps(29) forwards;
        animation: ani 0.7s steps(29) forwards;
      }
    }

    .mas {
      position: absolute;
      color: #000;
      right: 0px;
      left: 0px;
      transform: translate(0px, 8px);
      width: 100%;
      position: absolute;
      overflow: hidden;
      font-weight: $font-semibold;
    }
    
  }
  

  @-webkit-keyframes ani {
    from {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }
  
    to {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }
  }
  
  @keyframes ani {
    from {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }
  
    to {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }
  }
  
  @-webkit-keyframes ani2 {
    from {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }
  
    to {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }
  }
  
  @keyframes ani2 {
    from {
      -webkit-mask-position: 100% 0;
      mask-position: 100% 0;
    }
  
    to {
      -webkit-mask-position: 0 0;
      mask-position: 0 0;
    }
  }

.home-banner
{
  display: flex;
  flex-wrap: wrap;

  .text-sec
  {
    margin-top: 100px;
    .heading
    {
      @include quinn-text-bold();
      font-size: 3rem;
      text-align: left;
      line-height: 55px;
      margin-left: 120px;
    }

    .text-sec 
    {
      margin-top: 70px;
    }

    .para-text
    {
      text-align: left;
      margin-left: 120px;
    }

    .g-review
    {
      background-color: #1d1d1d;
      height: 100px;
      color: #fff;
      padding: 30px 100px;
      display: flex;
      gap: 1rem;
      align-items: center;
      margin-top: 30px;
      svg
      {
        width: 70px;
      }

      .google-review-text
      {
        display: flex;
        flex-wrap: wrap;
        p 
        {
          margin: 0px 5px 0px 0px;
          color: #fff;
          font-weight: $font-regular;
          text-underline-position: under;

          a 
          {
            color: #fff;
            font-weight: $font-semibold;
            transition: 0.4s;

            &:hover 
            {
              color: $sec-color;
            }
          }
        }

        .stars
        {
          svg 
          {
            width: 25px;
            fill: $sec-color
          }
        }
      }
    }
  }

  .img-sec
  {
  }
}

.footer
{
   background-color: $bg-pri-color;
  position: relative;

  .black-sqaure
  {
    padding: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: $bg-sec-color;
    position: absolute;
    transform: translatey(-150px);
    width: 400px;

    .logo 
    {
      margin-bottom: 50px;
    }

    .contact-details
    {
      color: #fff;

      .contact-inner{
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        gap: 0.8rem;
        margin-bottom: 1.5rem;
        text-align: left;

        svg 
        {
          width: 22px;
          fill: #ffffff;
        }

        p,a
        {
          color: #fff;
          font-weight: $font-medium;
          text-decoration: none;
          text-align: left;
        }
      }
    }

    .social 
    {
      margin-top: 20px;
      p
      {
        font-size: 18px;
        font-weight: $font-semibold;
        color: #fff;
        text-align: left;
      }

      .social-sec
      {
        text-align: left;
        svg
        {
          width: 30px;
          height: 30px;
          fill: $bg-sec-white;
          margin: 0px 10px 0px 0px;
          transition: 0.2s;

          &:hover
          {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .links 
  {
    p 
    {
      @include quinn-text-bold();
      font-size: 22px;
      text-align: left;
      padding-top: 50px;
      margin-bottom: 2rem;
    }

    ul 
    {
      text-align: left;
    list-style: none;
    padding-left: 0px;
      
      li 
      {
        margin-bottom: 20px;
        text-transform: capitalize;
        
        a 
        {
          text-decoration: none;
        color: $bg-sec-color;
        transition: 0.4s;

        &:hover
        {
          color: $sec-color;
        }
        }
      }
    }
  }

  .subscribe 
  {
    padding: 50px 0px;
    text-align: left;

    label
    {
      @include quinn-text-bold();
      font-size: 22px;
      text-align: left;
      margin-bottom: 1rem;
    }

    input 
    {
      width: 100%;
      border-radius: 1px;
      padding: 10px;
      border: 1px solid #1d1d1d;
    }

    .button-container {
      position: relative;
      overflow: hidden;
      border: 1px solid #000;
      transition: 0.5s;
      margin-top: 20px;
      height: 50px;
      width: 100%;
  
      .nav-link
      {
        border-radius: 0px;
      }
  
      .btn {
        height: 100%;
        width: 100%;
    border-radius: 0px;
        @include button(
          #000,
          "https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png",
          3000%,
          100%,
          none,
          #fff
        );
        cursor: pointer;
        -webkit-animation: ani2 0.7s steps(29) forwards;
        animation: ani2 0.7s steps(29) forwards;
        text-align: center;
        padding: 12px;
    
        &:hover {
          -webkit-animation: ani 0.7s steps(29) forwards;
          animation: ani 0.7s steps(29) forwards;
        }
      }
  
      .mas {
        position: absolute;
        color: #000;
        right: 0px;
        left: 0px;
        transform: translate(167px, 10px);
        width: 100%;
        position: absolute;
        overflow: hidden;
        font-weight: $font-semibold;
      }
      
    }
    


  }
}

.mini-footer 
{
  background-color: $bg-sec-white;
  .mini-footer-inner
  {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    font-weight: $font-semibold;

    p
    {
      margin-bottom: 0px;
    } 
  }
}

.static-numbers-sec, .services-sec, .why-choose-moks-sec, .work-process-sec, .client-reviews, .working-area, .about-us, .about-main-sec-1, .about-main-sec-2, .our-teams, .contact-us, .contact-us-right, .tax-services, .direct-tax-service, .indirect-tax-service, .faqs-sec
{
  padding: 100px 0px;

  .heding-sec
  {
    position: relative;

    &::after
    {
      content: "";
      position: absolute;
      width: 10px;
      height: 100%;
      background-color: #FFC107;
      left: 0;
      top: 0;
    }

    .sec-heading
    {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 1rem;
      margin-left: 25px;
      padding: 10px 0px;
    }

    .small-heading
    {
      font-size: $sec-sideheading-size;
      letter-spacing: 3px;
      font-weight: $font-regular;
      margin-bottom: 0px;
      text-transform: uppercase

    }

    .main-heading 
    {
      @include quinn-text-bold();
      font-size: $sec-bigheading-text-size;
      width: 70%;
      margin-bottom: 0px;
    }
  }
}

.static-details
{
  margin: 100px 0px 0px 0px;
  .static-content
  {
    position: relative;
    display: flex;
    flex-direction: row;
    

    .static-number 
    {
      font-size: 6rem;
      color: $sec-text-color;
      font-weight: $font-bold;
      position: absolute;
      z-index: -1;
      left: -22px;
    }

    .static-content-heading
    {
        @include quinn-text-bold();
        font-size: $sec-smallheading-text-size;
        line-height: 30px;
    }

  }
}

.services-sec
{
  background-color: $bg-pri-color;
  .key-btn
  {
    margin-top: 20px;
    background-color: $sec-color;
    padding: 15px 50px;
    p 
    {
      margin: 0px;
      color: $pri-text-color;
      letter-spacing: 2px;
      text-align: center;
    }
  }
  .services-cards
  {
    .card 
    {
      padding: 30px;
      border: 0px;
      border-radius: 0px;
      justify-items: center;
      align-items: center;
      box-shadow: 0 3px 6px 0 #a4a4a440;
      border-radius: 20px;

    }

    .circle-div
    {
      width: 100px;
      height: 100px;
      padding: 10px;
      background: $bg-pri-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      img 
      {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .card-title
    {
      margin-top: 30px;
      @include quinn-text-bold();
      font-size: $sec-smallheading-text-size;
      margin-bottom: 0px;
      line-height: 30px;
      font-size: 1.4rem;

      p 
      {
        text-align: center;
      }
    }

    .card-description
    {
      font-weight: $font-regular;
      p 
      {
        text-align: center;
      }
    }

    .read-more-btn
    {
      margin-top: 10px;
      svg
      {
        fill: $bg-sec-color;
        width: 20px;
        margin-left: 5px;
      }

      a 
      {
        color: $pri-text-color;
        text-decoration: none;
        font-weight: $font-semibold;
        transition: 0.4s;
      }
    }
  }
}

.why-choose-moks-sec 
{
  .key-card
  {
      background-color: $bg-pri-color;
    padding: 20px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    text-transform: uppercase;
    font-weight: $font-semibold;
    box-shadow: 0 3px 6px 0 rgba(164, 164, 164, 0.2509803922);
    border-radius: 10px;
  

    svg 
    {
      width: 35px;
    }

    p 
    {
      margin-bottom: 0px;
      letter-spacing: 2px;
      line-height: 20px;
    }
  }
}

.work-process-sec
{
  background-color: $bg-pri-color;
  .prcess-cards
  {
    margin: 5px 0px;
    background-color: $bg-sec-white;
    padding: 40px 0px 40px 60px;
    box-shadow: 0 3px 6px 0 rgba(164, 164, 164, 0.2509803922);
    border-radius: 10px;

    .steps-cards
    {
      position: relative;
      display: flex;
      flex-direction: row;
      
      .steps-number 
      {
        font-size: 6rem;
        color: $sec-text-color;
        font-weight: $font-bold;
        position: absolute;
        left: -82px;
      }

      .steps-content-heading
      {
          @include quinn-text-bold();
          font-size: 1.3rem;
          line-height: 30px;
          margin: 0px;
          text-transform: none;
          font-weight: 300;
      }

    }
  }
}

.client-reviews
{
  .review-slider
  {
    margin-top: 50px;
  }

  .client-logo
  {
    width: 120px;
    height: 120px;
    background: #fff;
    border-radius: 50%;

    img 
    {
      width: 120px;
    }
  }

  .review-stars 
  {
    text-align: left;
    margin: 20px 0px;
    svg
    {
      width: 25px;
      fill: $sec-color;
    }

  }

    .client-name 
    {
      p 
      {
        font-weight: $font-bold;
      }
    }

    .slick-dots
    {
      text-align: left;
    }

    .slick-dots
    {
      font-size: 10px;
    }
}

.working-area
{
  
  margin-bottom: 200px;
  .industry-list, .call-discuss-keys
  {
    margin: 50px 0px;
    .industry-names, .call-discuss-keys-points
    {
      margin: 10px 0px;
      display: flex;
      align-items: center;

      svg 
      {
        width: 25px;
        fill: $bg-sec-color;
      }

      p 
      {
        margin: 0px 0px 0px 10px;
      }
    }
  }
}

.about-us
{
  margin: 0px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .about-main-sec
    {

    }
}

.our-teams
{
  margin-bottom: 300px;

  .teams-card
  {
    margin: 100px 0px;

    .team-member-details
    {
      margin: 20px 0px;
      .team-name
      {
        @include quinn-text-bold();
        font-size: 1.6rem;
      }

      .team-position
      {
        text-transform: uppercase;
        font-weight: $font-regular;
        margin-bottom: 0.5rem;
      }

      .team-role
      {
        font-style: italic;
        text-transform: uppercase;
        font-weight: $font-regular;
      }

      .btn 
      {
        background: $bg-sec-color;
        color: #fff;
        border-radius: 0px;
        padding: 10px 30px;
        font-weight: $font-regular;
        letter-spacing: 1px;
        border: 1px solid $bg-sec-color;
        transition: 0.4s;

        &:hover 
        {
          background-color: $bg-pri-color;
          border: 1px solid $bg-sec-color;
          color: $bg-sec-color;

          svg 
          {
            fill: $bg-sec-color;
            transform: translateX(5px);
          }
        }
      }

      svg 
      {
        fill: #fff;
      width: 20px;
      margin-left: 2px;
      transition: 0.4s;
      }

    }

    .team-card-2
      {
        transform: translateY(-80px);
      }

      .team-card-3
      {
        transform: translateY(-140px);
      }

      
  }

  .modal 
  {
   

    .modal-content
    {
      position: relative;
      border: 0px;
      border-radius: 0px;
    }

    .modal-inner
    {
      &::before
      {
        content: '';
        height: 100%;
        width: 280px;
        position: absolute;
        left: 0;
        background: $bg-sec-color;
      }
    }

    .modal-header
    {
      border: 0px;

      .btn-close
      {
        --bs-btn-close-opacity: 1;
      }
    }

    .modal-body 
    {
      padding: 2rem;

      .team-profile-details
      {
        .main-heading
        {
          font-size: 2rem;
          line-height: 30px;
        }

        .small-heading
        {
          font-size: 1rem;
        }

        .team-profile-discri
        {
          margin: 30px 0px;
        }
      }
    }

    .modal-footer
    {
      border: 0px;
    }

    .modal-footer 
    {
     .btn 
      {
        background: $bg-sec-color;
        color: #fff;
        border-radius: 0px;
        padding: 10px 30px;
        font-weight: $font-regular;
        letter-spacing: 1px;
        border: 1px solid $bg-sec-color;
        transition: 0.4s;

        &:hover 
        {
          background-color: $bg-pri-color;
          border: 1px solid $bg-sec-color;
          color: $bg-sec-color;

          svg 
          {
            fill: $bg-sec-color;
            transform: translateX(5px);
            width: 20px;
          }

          
        }
        svg 
          {
            fill: #fff;
          width: 20px;
          margin-left: 2px;
          transition: 0.4s;
          }
      }
    }
  }
}

.contact-us-form 
{
  margin-bottom: 300px;

  .contact-us-right 
  {
    margin: 50px 0px;
    background-color: $bg-sec-white;
    padding: 30px 80px 30px 30px;

    .sec-title
    {
      .heding-sec
      {
        .sec-heading 
        {
          .small-heading
          {
            font-size: 1rem;
          }

          .main-heading 
          {
            font-size: 2rem;
            line-height: 40px;
          }
        }
      }
    }

    .contact-us-right-details
    {
      margin: 20px 0px;

      p
      {
        font-weight: $font-regular;
      }

      .call-discuss-keys
      {

        margin: 30px 0px;
        
        .call-discuss-keys-points
        {
          margin: 15px 0px;
          display: flex;
          align-items: flex-start;
  
          svg 
          {
            width: 25px;
            fill: $bg-sec-color;
          }
  
          p 
          {
            margin: 0px 0px 0px 10px;
          }
        }
      }
    }
  }

  .contact-details
  {
    color: $pri-text-color;

    .contact-inner{
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      gap: 0.8rem;
      margin-bottom: 1.5rem;
      text-align: left;

      svg 
      {
        width: 22px;
        fill: $pri-text-color;
      }

      p,a
      {
        color: $pri-text-color;
        font-weight: $font-semibold;
        text-decoration: none;
        text-align: left;
      }
    }
  }

  .contact-form
  {
    padding: 30px;
    background-color: $bg-sec-color;
    color: $bg-sec-white;

    .contact-title 
    {
      h2 
      {
        @include quinn-text-bold();
        font-size: 1.8rem;
      }

      p 
      {
        font-weight: $font-regular;
      }

      
    }

      label
      {
        margin-bottom: 10px;
      }

      .form-control, select
      {
        margin-bottom: 20px;
        border-radius: 0px;
        height: 50px;

        &:focus {
          border-color: #ffc107;
          outline: 0;
          box-shadow: 0 0 0 .10rem rgb(255 193 7);
        }
      }

      textarea 
      {
        height: 200px !important;
      }

      button 
      {
        margin-top: 20px;
        width: 100%;
        padding: 15px;
        font-weight: 600;
        background: #ffc107;
        border: 1px solid #ffc107;
        transition: 0.4s;

        svg 
          {
            transition: 0.4s;
            path 
            {
              fill: $bg-sec-color;
            }
          }

        &:hover
        {
          background-color: $bg-sec-color;
          border: 1px solid $sec-color;
          color: $sec-color;

          svg 
          {
            transform: translateX(5px);
            
            path 
            {
              fill: $sec-color !important;
            }
          }
        }
      }
     
  }

  
}

.semi-bold
{
  font-weight: $font-semibold !important;
} 

.tax-sercices-sec
{
  margin: 50px 0px 200px 0px;
  padding-top: 50px;
  .direct-tax-service
  {
    .heding-sec
    {
      margin: 50px 0px;
    }
  }

  .service-content
  {
    h2 
    {
      font-size: 1rem;
      margin-bottom: 30px;
      font-weight: $font-semibold;
    }

    p, ol, li
    {
      font-weight: $font-regular;
    }

    .tax-offers 
    {
      h2 
      {
        margin-top: 50px;
      }
      
      ol
      {
        li 
        {
          margin-bottom: 10px;
        }
      }

      ul 
      {
        li 
        {
          margin-bottom: 0px;
        }
      }
    }
  }

  .faqs-sec
  {
    .faqs-content
    {
      margin: 50px 0px;

      .accordion-button:not(.collapsed)
      {
        background-color: unset;
        border: unset;
      }

      .accordion-button
      {
       font-weight: $font-semibold;
       padding: 22px;
      }

      .accordion-button:focus
      {
        box-shadow: unset;
      }

      .accordion-collapse
      {
        font-weight: $font-regular;
      }
    }
  }

}

.dropdown-menu
{
  border: 0px !important;
  border-radius: 0px !important;
}

.dropdown-item.active, .dropdown-item:active
{
  background-color: $bg-sec-color !important;
}


@media only screen and (max-width: 576px)
{   
    body
    {
        overflow-x: hidden;
    }

  .home-banner .text-sec .heading
  {
    font-size: 2rem;
    margin: 0px 15px 0px 15px;
    line-height: 44px;
  }

  .mini-header
  {
    display: none;
  }

  .navbar-toggler
  {
    background-color: $bg-sec-color;
  }

  .button-container
  {
    width: 100%;
  }

  .home-banner .text-sec .para-text
  {
    margin: 0px 15px 0px 15px;
  }

  .home-banner .text-sec {
    margin-top: 50px;
  }



  .home-banner .text-sec .g-review
  {
    padding: 30px 10px;
    height: 150px;
  }

  .home-banner .text-sec .g-review .google-review-text
  {
    gap: 0.3rem;
  }

  .static-numbers-sec, .services-sec, .why-choose-moks-sec, .work-process-sec, .client-reviews, .working-area, .about-us, .about-main-sec-1, .about-main-sec-2, .our-teams, .contact-us, .contact-us-right, .tax-services, .direct-tax-service, .indirect-tax-service, .faqs-sec {
    padding: 50px 0px;
    
    
  }

  .static-numbers-sec .heding-sec .main-heading, .services-sec .heding-sec .main-heading, .why-choose-moks-sec .heding-sec .main-heading, .work-process-sec .heding-sec .main-heading, .client-reviews .heding-sec .main-heading, .working-area .heding-sec .main-heading, .about-us .heding-sec .main-heading, .about-main-sec-1 .heding-sec .main-heading, .about-main-sec-2 .heding-sec .main-heading, .our-teams .heding-sec .main-heading, .contact-us .heding-sec .main-heading, .contact-us-right .heding-sec .main-heading, .tax-services .heding-sec .main-heading, .direct-tax-service .heding-sec .main-heading, .indirect-tax-service .heding-sec .main-heading, .faqs-sec .heding-sec .main-heading 
  {
    font-size: 1.8rem;
    line-height: 35px;
    width: 100%;
  }

  .static-numbers-sec .heding-sec .small-heading, .services-sec .heding-sec .small-heading, .why-choose-moks-sec .heding-sec .small-heading, .work-process-sec .heding-sec .small-heading, .client-reviews .heding-sec .small-heading, .working-area .heding-sec .small-heading, .about-us .heding-sec .small-heading, .about-main-sec-1 .heding-sec .small-heading, .about-main-sec-2 .heding-sec .small-heading, .our-teams .heding-sec .small-heading, .contact-us .heding-sec .small-heading, .contact-us-right .heding-sec .small-heading, .tax-services .heding-sec .small-heading, .direct-tax-service .heding-sec .small-heading, .indirect-tax-service .heding-sec .small-heading, .faqs-sec .heding-sec .small-heading {
    font-size: 1rem;
  }

  .static-details {
    
    padding: 20px;
  }

  .static-details .static-content {
    margin-bottom: 30px;
    }

    .static-details .static-content .static-content-heading
    {
      margin-bottom: 10px !important;
    }
  .services-sec .row, .why-choose-moks-sec .row
  {
    gap: 1rem;
  }

  .services-sec .row .mt-4, .why-choose-moks-sec .row .mt-4
  {
    margin-top: 0rem !important;
  
  }
  .why-choose-moks-sec .row, .work-process-sec .row
  {
    flex-direction: column-reverse;
  }

  .work-process-sec .prcess-cards {
    margin: 5px 0px 5px 15px;
    padding: 30px 0px 30px 60px;
  }

  .prcess-cards.w-75
  {
    width: 100% !important;
  }

  .client-reviews
  {
    display: none;
  }

  .work-process-sec .prcess-cards .steps-cards .steps-content-heading {
    font-size: 1.2rem;
    line-height: 25px;
    margin: 0px;
  }

  .work-process-sec .prcess-cards .steps-cards .steps-number {
    font-size: 5rem;
  }

  .footer .black-sqaure {
  position: unset;
  transform: unset;
  }

  .footer .subscribe {
    padding: 30px 0px;
    text-align: left;
  }

  .mini-footer .mini-footer-inner p
  {
    margin-bottom: 10px !important;
  }
  
  .mini-footer .mini-footer-inner {
    padding: 10px;
  }

  .footer .links ul li {
    margin-bottom: 10px;
  }

  .footer .black-sqaure {
    padding: 30px;
    width: 100%;
  }

  .our-teams .teams-card {
    margin: 50px 0px;
  }

  .team-card-2, .team-card-3
  {
    transform: unset !important;
  }

  .about-us {
    height: 200px;
  }

  .about-main-sec-1 img
  {
    margin-bottom: 30px !important;
  }

  .our-teams .modal .modal-inner::before {
    content: "";
    height: 27%;
  }

  .team-profile-details .sec-heading
  {
    margin-top: 120px;
  }

  .our-teams .modal .modal-body {
    padding: 1rem;
  }

  .contact-us-form .contact-us-right {
    padding: 20px 20px 20px 20px;
    }

    .contact-us-form .contact-us-right .contact-us-right-details .call-discuss-keys .call-discuss-keys-points svg {
      display: none;
  }

  .tax-sercices-sec {
    margin: 0px;
    padding-top: 0px;
  }

  .working-area {
    margin-bottom: 0px;
}

}