@import url("https://use.typekit.net/vxo7nwa.css");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  scroll-behavior: smooth;
}

html {
  overflow-x: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  margin: 0px;
  padding: 0px;
  color: #1D1D1D;
  background-color: #ffffff;
  box-sizing: border-box;
  line-height: 25px !important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #1a1a1a;
}

::-webkit-scrollbar-thumb:hover {
  background: #FFC107;
}

.logo {
  width: 90px;
  -o-object-fit: fill;
     object-fit: fill;
}

p {
  text-align: left;
}

a {
  color: #1a1a1a;
  text-underline-position: under;
}

.mini-header {
  display: block;
  padding: 10px;
  background-color: #F9F9F9;
}
.mini-header .mini-header-divs {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.mini-header .mini-header-divs .contact-sec {
  display: flex;
  flex-wrap: nowrap;
}
.mini-header .mini-header-divs .contact-sec div {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0px 15px 0px 0px;
}
.mini-header .mini-header-divs .contact-sec div a {
  color: #1a1a1a;
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
  transition: 0.4s ease-out;
}
.mini-header .mini-header-divs .contact-sec div:hover {
  text-decoration: underline;
  text-underline-position: under;
}
.mini-header svg {
  width: 20px;
  height: 20px;
  fill: #1a1a1a;
  margin: 0px 10px 0px 0px;
  transition: 0.2s;
}

.navbar {
  background-color: #ffffff;
}
.navbar .navbar-brand {
  padding: 0px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  font-weight: 600;
  padding: 0.5rem 1rem;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  text-decoration: underline;
  text-underline-position: under;
  font-weight: 700;
}

.button-container {
  position: relative;
  overflow: hidden;
  border: 1px solid #000;
  transition: 0.5s;
  width: 170px;
}
.button-container .nav-link {
  border-radius: 0px;
}
.button-container .btn {
  height: 100%;
  background: #000;
  -webkit-mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png");
  mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png");
  -webkit-mask-size: 3000% 100%;
  mask-size: 3000% 100%;
  border: none;
  color: #fff;
  cursor: pointer;
  animation: ani2 0.7s steps(29) forwards;
}
.button-container .btn:hover {
  animation: ani 0.7s steps(29) forwards;
}
.button-container .mas {
  position: absolute;
  color: #000;
  right: 0px;
  left: 0px;
  transform: translate(0px, 8px);
  width: 100%;
  position: absolute;
  overflow: hidden;
  font-weight: 600;
}
@keyframes ani {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}
@keyframes ani2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}
.home-banner {
  display: flex;
  flex-wrap: wrap;
}
.home-banner .text-sec {
  margin-top: 100px;
}
.home-banner .text-sec .heading {
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  font-size: 3rem;
  text-align: left;
  line-height: 55px;
  margin-left: 120px;
}
.home-banner .text-sec .text-sec {
  margin-top: 70px;
}
.home-banner .text-sec .para-text {
  text-align: left;
  margin-left: 120px;
}
.home-banner .text-sec .g-review {
  background-color: #1d1d1d;
  height: 100px;
  color: #fff;
  padding: 30px 100px;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 30px;
}
.home-banner .text-sec .g-review svg {
  width: 70px;
}
.home-banner .text-sec .g-review .google-review-text {
  display: flex;
  flex-wrap: wrap;
}
.home-banner .text-sec .g-review .google-review-text p {
  margin: 0px 5px 0px 0px;
  color: #fff;
  font-weight: 400;
  text-underline-position: under;
}
.home-banner .text-sec .g-review .google-review-text p a {
  color: #fff;
  font-weight: 600;
  transition: 0.4s;
}
.home-banner .text-sec .g-review .google-review-text p a:hover {
  color: #FFC107;
}
.home-banner .text-sec .g-review .google-review-text .stars svg {
  width: 25px;
  fill: #FFC107;
}
.footer {
  background-color: #F9F9F9;
  position: relative;
}
.footer .black-sqaure {
  padding: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #1a1a1a;
  position: absolute;
  transform: translatey(-150px);
  width: 400px;
}
.footer .black-sqaure .logo {
  margin-bottom: 50px;
}
.footer .black-sqaure .contact-details {
  color: #fff;
}
.footer .black-sqaure .contact-details .contact-inner {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 0.8rem;
  margin-bottom: 1.5rem;
  text-align: left;
}
.footer .black-sqaure .contact-details .contact-inner svg {
  width: 22px;
  fill: #ffffff;
}
.footer .black-sqaure .contact-details .contact-inner p, .footer .black-sqaure .contact-details .contact-inner a {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  text-align: left;
}
.footer .black-sqaure .social {
  margin-top: 20px;
}
.footer .black-sqaure .social p {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-align: left;
}
.footer .black-sqaure .social .social-sec {
  text-align: left;
}
.footer .black-sqaure .social .social-sec svg {
  width: 30px;
  height: 30px;
  fill: #ffffff;
  margin: 0px 10px 0px 0px;
  transition: 0.2s;
}
.footer .black-sqaure .social .social-sec svg:hover {
  transform: scale(1.2);
}
.footer .links p {
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  font-size: 22px;
  text-align: left;
  padding-top: 50px;
  margin-bottom: 2rem;
}
.footer .links ul {
  text-align: left;
  list-style: none;
  padding-left: 0px;
}
.footer .links ul li {
  margin-bottom: 20px;
  text-transform: capitalize;
}
.footer .links ul li a {
  text-decoration: none;
  color: #1a1a1a;
  transition: 0.4s;
}
.footer .links ul li a:hover {
  color: #FFC107;
}
.footer .subscribe {
  padding: 50px 0px;
  text-align: left;
}
.footer .subscribe label {
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  font-size: 22px;
  text-align: left;
  margin-bottom: 1rem;
}
.footer .subscribe input {
  width: 100%;
  border-radius: 1px;
  padding: 10px;
  border: 1px solid #1d1d1d;
}
.footer .subscribe .button-container {
  position: relative;
  overflow: hidden;
  border: 1px solid #000;
  transition: 0.5s;
  margin-top: 20px;
  height: 50px;
  width: 100%;
}
.footer .subscribe .button-container .nav-link {
  border-radius: 0px;
}
.footer .subscribe .button-container .btn {
  height: 100%;
  width: 100%;
  border-radius: 0px;
  background: #000;
  -webkit-mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png");
  mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/urban-sprite.png");
  -webkit-mask-size: 3000% 100%;
  mask-size: 3000% 100%;
  border: none;
  color: #fff;
  cursor: pointer;
  animation: ani2 0.7s steps(29) forwards;
  text-align: center;
  padding: 12px;
}
.footer .subscribe .button-container .btn:hover {
  animation: ani 0.7s steps(29) forwards;
}
.footer .subscribe .button-container .mas {
  position: absolute;
  color: #000;
  right: 0px;
  left: 0px;
  transform: translate(167px, 10px);
  width: 100%;
  position: absolute;
  overflow: hidden;
  font-weight: 600;
}

.mini-footer {
  background-color: #ffffff;
}
.mini-footer .mini-footer-inner {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  font-weight: 600;
}
.mini-footer .mini-footer-inner p {
  margin-bottom: 0px;
}

.static-numbers-sec, .services-sec, .why-choose-moks-sec, .work-process-sec, .client-reviews, .working-area, .about-us, .about-main-sec-1, .about-main-sec-2, .our-teams, .contact-us, .contact-us-right, .tax-services, .direct-tax-service, .indirect-tax-service, .faqs-sec {
  padding: 100px 0px;
}
.static-numbers-sec .heding-sec, .services-sec .heding-sec, .why-choose-moks-sec .heding-sec, .work-process-sec .heding-sec, .client-reviews .heding-sec, .working-area .heding-sec, .about-us .heding-sec, .about-main-sec-1 .heding-sec, .about-main-sec-2 .heding-sec, .our-teams .heding-sec, .contact-us .heding-sec, .contact-us-right .heding-sec, .tax-services .heding-sec, .direct-tax-service .heding-sec, .indirect-tax-service .heding-sec, .faqs-sec .heding-sec {
  position: relative;
}
.static-numbers-sec .heding-sec::after, .services-sec .heding-sec::after, .why-choose-moks-sec .heding-sec::after, .work-process-sec .heding-sec::after, .client-reviews .heding-sec::after, .working-area .heding-sec::after, .about-us .heding-sec::after, .about-main-sec-1 .heding-sec::after, .about-main-sec-2 .heding-sec::after, .our-teams .heding-sec::after, .contact-us .heding-sec::after, .contact-us-right .heding-sec::after, .tax-services .heding-sec::after, .direct-tax-service .heding-sec::after, .indirect-tax-service .heding-sec::after, .faqs-sec .heding-sec::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  background-color: #FFC107;
  left: 0;
  top: 0;
}
.static-numbers-sec .heding-sec .sec-heading, .services-sec .heding-sec .sec-heading, .why-choose-moks-sec .heding-sec .sec-heading, .work-process-sec .heding-sec .sec-heading, .client-reviews .heding-sec .sec-heading, .working-area .heding-sec .sec-heading, .about-us .heding-sec .sec-heading, .about-main-sec-1 .heding-sec .sec-heading, .about-main-sec-2 .heding-sec .sec-heading, .our-teams .heding-sec .sec-heading, .contact-us .heding-sec .sec-heading, .contact-us-right .heding-sec .sec-heading, .tax-services .heding-sec .sec-heading, .direct-tax-service .heding-sec .sec-heading, .indirect-tax-service .heding-sec .sec-heading, .faqs-sec .heding-sec .sec-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin-left: 25px;
  padding: 10px 0px;
}
.static-numbers-sec .heding-sec .small-heading, .services-sec .heding-sec .small-heading, .why-choose-moks-sec .heding-sec .small-heading, .work-process-sec .heding-sec .small-heading, .client-reviews .heding-sec .small-heading, .working-area .heding-sec .small-heading, .about-us .heding-sec .small-heading, .about-main-sec-1 .heding-sec .small-heading, .about-main-sec-2 .heding-sec .small-heading, .our-teams .heding-sec .small-heading, .contact-us .heding-sec .small-heading, .contact-us-right .heding-sec .small-heading, .tax-services .heding-sec .small-heading, .direct-tax-service .heding-sec .small-heading, .indirect-tax-service .heding-sec .small-heading, .faqs-sec .heding-sec .small-heading {
  font-size: 1.125rem;
  letter-spacing: 3px;
  font-weight: 400;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.static-numbers-sec .heding-sec .main-heading, .services-sec .heding-sec .main-heading, .why-choose-moks-sec .heding-sec .main-heading, .work-process-sec .heding-sec .main-heading, .client-reviews .heding-sec .main-heading, .working-area .heding-sec .main-heading, .about-us .heding-sec .main-heading, .about-main-sec-1 .heding-sec .main-heading, .about-main-sec-2 .heding-sec .main-heading, .our-teams .heding-sec .main-heading, .contact-us .heding-sec .main-heading, .contact-us-right .heding-sec .main-heading, .tax-services .heding-sec .main-heading, .direct-tax-service .heding-sec .main-heading, .indirect-tax-service .heding-sec .main-heading, .faqs-sec .heding-sec .main-heading {
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  font-size: 2.5rem;
  width: 70%;
  margin-bottom: 0px;
}

.static-details {
  margin: 100px 0px 0px 0px;
}
.static-details .static-content {
  position: relative;
  display: flex;
  flex-direction: row;
}
.static-details .static-content .static-number {
  font-size: 6rem;
  color: #FFC107;
  font-weight: 700;
  position: absolute;
  z-index: -1;
  left: -22px;
}
.static-details .static-content .static-content-heading {
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  font-size: 1.5rem;
  line-height: 30px;
}

.services-sec {
  background-color: #F9F9F9;
}
.services-sec .key-btn {
  margin-top: 20px;
  background-color: #FFC107;
  padding: 15px 50px;
}
.services-sec .key-btn p {
  margin: 0px;
  color: #1a1a1a;
  letter-spacing: 2px;
  text-align: center;
}
.services-sec .services-cards .card {
  padding: 30px;
  border: 0px;
  border-radius: 0px;
  justify-items: center;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(164, 164, 164, 0.2509803922);
  border-radius: 20px;
}
.services-sec .services-cards .circle-div {
  width: 100px;
  height: 100px;
  padding: 10px;
  background: #F9F9F9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-sec .services-cards .circle-div img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.services-sec .services-cards .card-title {
  margin-top: 30px;
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  font-size: 1.5rem;
  margin-bottom: 0px;
  line-height: 30px;
  font-size: 1.4rem;
}
.services-sec .services-cards .card-title p {
  text-align: center;
}
.services-sec .services-cards .card-description {
  font-weight: 400;
}
.services-sec .services-cards .card-description p {
  text-align: center;
}
.services-sec .services-cards .read-more-btn {
  margin-top: 10px;
}
.services-sec .services-cards .read-more-btn svg {
  fill: #1a1a1a;
  width: 20px;
  margin-left: 5px;
}
.services-sec .services-cards .read-more-btn a {
  color: #1a1a1a;
  text-decoration: none;
  font-weight: 600;
  transition: 0.4s;
}

.why-choose-moks-sec .key-card {
  background-color: #F9F9F9;
  padding: 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 0 3px 6px 0 rgba(164, 164, 164, 0.2509803922);
  border-radius: 10px;
}
.why-choose-moks-sec .key-card svg {
  width: 35px;
}
.why-choose-moks-sec .key-card p {
  margin-bottom: 0px;
  letter-spacing: 2px;
  line-height: 20px;
}

.work-process-sec {
  background-color: #F9F9F9;
}
.work-process-sec .prcess-cards {
  margin: 5px 0px;
  background-color: #ffffff;
  padding: 40px 0px 40px 60px;
  box-shadow: 0 3px 6px 0 rgba(164, 164, 164, 0.2509803922);
  border-radius: 10px;
}
.work-process-sec .prcess-cards .steps-cards {
  position: relative;
  display: flex;
  flex-direction: row;
}
.work-process-sec .prcess-cards .steps-cards .steps-number {
  font-size: 6rem;
  color: #FFC107;
  font-weight: 700;
  position: absolute;
  left: -82px;
}
.work-process-sec .prcess-cards .steps-cards .steps-content-heading {
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  font-size: 1.3rem;
  line-height: 30px;
  margin: 0px;
  text-transform: none;
  font-weight: 300;
}

.client-reviews .review-slider {
  margin-top: 50px;
}
.client-reviews .client-logo {
  width: 120px;
  height: 120px;
  background: #fff;
  border-radius: 50%;
}
.client-reviews .client-logo img {
  width: 120px;
}
.client-reviews .review-stars {
  text-align: left;
  margin: 20px 0px;
}
.client-reviews .review-stars svg {
  width: 25px;
  fill: #FFC107;
}
.client-reviews .client-name p {
  font-weight: 700;
}
.client-reviews .slick-dots {
  text-align: left;
}
.client-reviews .slick-dots {
  font-size: 10px;
}

.working-area {
  margin-bottom: 200px;
}
.working-area .industry-list, .working-area .call-discuss-keys {
  margin: 50px 0px;
}
.working-area .industry-list .industry-names, .working-area .industry-list .call-discuss-keys-points, .working-area .call-discuss-keys .industry-names, .working-area .call-discuss-keys .call-discuss-keys-points {
  margin: 10px 0px;
  display: flex;
  align-items: center;
}
.working-area .industry-list .industry-names svg, .working-area .industry-list .call-discuss-keys-points svg, .working-area .call-discuss-keys .industry-names svg, .working-area .call-discuss-keys .call-discuss-keys-points svg {
  width: 25px;
  fill: #1a1a1a;
}
.working-area .industry-list .industry-names p, .working-area .industry-list .call-discuss-keys-points p, .working-area .call-discuss-keys .industry-names p, .working-area .call-discuss-keys .call-discuss-keys-points p {
  margin: 0px 0px 0px 10px;
}

.about-us {
  margin: 0px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.our-teams {
  margin-bottom: 300px;
}
.our-teams .teams-card {
  margin: 100px 0px;
}
.our-teams .teams-card .team-member-details {
  margin: 20px 0px;
}
.our-teams .teams-card .team-member-details .team-name {
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  font-size: 1.6rem;
}
.our-teams .teams-card .team-member-details .team-position {
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.our-teams .teams-card .team-member-details .team-role {
  font-style: italic;
  text-transform: uppercase;
  font-weight: 400;
}
.our-teams .teams-card .team-member-details .btn {
  background: #1a1a1a;
  color: #fff;
  border-radius: 0px;
  padding: 10px 30px;
  font-weight: 400;
  letter-spacing: 1px;
  border: 1px solid #1a1a1a;
  transition: 0.4s;
}
.our-teams .teams-card .team-member-details .btn:hover {
  background-color: #F9F9F9;
  border: 1px solid #1a1a1a;
  color: #1a1a1a;
}
.our-teams .teams-card .team-member-details .btn:hover svg {
  fill: #1a1a1a;
  transform: translateX(5px);
}
.our-teams .teams-card .team-member-details svg {
  fill: #fff;
  width: 20px;
  margin-left: 2px;
  transition: 0.4s;
}
.our-teams .teams-card .team-card-2 {
  transform: translateY(-80px);
}
.our-teams .teams-card .team-card-3 {
  transform: translateY(-140px);
}
.our-teams .modal .modal-content {
  position: relative;
  border: 0px;
  border-radius: 0px;
}
.our-teams .modal .modal-inner::before {
  content: "";
  height: 100%;
  width: 280px;
  position: absolute;
  left: 0;
  background: #1a1a1a;
}
.our-teams .modal .modal-header {
  border: 0px;
}
.our-teams .modal .modal-header .btn-close {
  --bs-btn-close-opacity: 1;
}
.our-teams .modal .modal-body {
  padding: 2rem;
}
.our-teams .modal .modal-body .team-profile-details .main-heading {
  font-size: 2rem;
  line-height: 30px;
}
.our-teams .modal .modal-body .team-profile-details .small-heading {
  font-size: 1rem;
}
.our-teams .modal .modal-body .team-profile-details .team-profile-discri {
  margin: 30px 0px;
}
.our-teams .modal .modal-footer {
  border: 0px;
}
.our-teams .modal .modal-footer .btn {
  background: #1a1a1a;
  color: #fff;
  border-radius: 0px;
  padding: 10px 30px;
  font-weight: 400;
  letter-spacing: 1px;
  border: 1px solid #1a1a1a;
  transition: 0.4s;
}
.our-teams .modal .modal-footer .btn:hover {
  background-color: #F9F9F9;
  border: 1px solid #1a1a1a;
  color: #1a1a1a;
}
.our-teams .modal .modal-footer .btn:hover svg {
  fill: #1a1a1a;
  transform: translateX(5px);
  width: 20px;
}
.our-teams .modal .modal-footer .btn svg {
  fill: #fff;
  width: 20px;
  margin-left: 2px;
  transition: 0.4s;
}

.contact-us-form {
  margin-bottom: 300px;
}
.contact-us-form .contact-us-right {
  margin: 50px 0px;
  background-color: #ffffff;
  padding: 30px 80px 30px 30px;
}
.contact-us-form .contact-us-right .sec-title .heding-sec .sec-heading .small-heading {
  font-size: 1rem;
}
.contact-us-form .contact-us-right .sec-title .heding-sec .sec-heading .main-heading {
  font-size: 2rem;
  line-height: 40px;
}
.contact-us-form .contact-us-right .contact-us-right-details {
  margin: 20px 0px;
}
.contact-us-form .contact-us-right .contact-us-right-details p {
  font-weight: 400;
}
.contact-us-form .contact-us-right .contact-us-right-details .call-discuss-keys {
  margin: 30px 0px;
}
.contact-us-form .contact-us-right .contact-us-right-details .call-discuss-keys .call-discuss-keys-points {
  margin: 15px 0px;
  display: flex;
  align-items: flex-start;
}
.contact-us-form .contact-us-right .contact-us-right-details .call-discuss-keys .call-discuss-keys-points svg {
  width: 25px;
  fill: #1a1a1a;
}
.contact-us-form .contact-us-right .contact-us-right-details .call-discuss-keys .call-discuss-keys-points p {
  margin: 0px 0px 0px 10px;
}
.contact-us-form .contact-details {
  color: #1a1a1a;
}
.contact-us-form .contact-details .contact-inner {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 0.8rem;
  margin-bottom: 1.5rem;
  text-align: left;
}
.contact-us-form .contact-details .contact-inner svg {
  width: 22px;
  fill: #1a1a1a;
}
.contact-us-form .contact-details .contact-inner p, .contact-us-form .contact-details .contact-inner a {
  color: #1a1a1a;
  font-weight: 600;
  text-decoration: none;
  text-align: left;
}
.contact-us-form .contact-form {
  padding: 30px;
  background-color: #1a1a1a;
  color: #ffffff;
}
.contact-us-form .contact-form .contact-title h2 {
  font-family: quinn-text, serif;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: normal;
  font-size: 1.8rem;
}
.contact-us-form .contact-form .contact-title p {
  font-weight: 400;
}
.contact-us-form .contact-form label {
  margin-bottom: 10px;
}
.contact-us-form .contact-form .form-control, .contact-us-form .contact-form select {
  margin-bottom: 20px;
  border-radius: 0px;
  height: 50px;
}
.contact-us-form .contact-form .form-control:focus, .contact-us-form .contact-form select:focus {
  border-color: #ffc107;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgb(255, 193, 7);
}
.contact-us-form .contact-form textarea {
  height: 200px !important;
}
.contact-us-form .contact-form button {
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  font-weight: 600;
  background: #ffc107;
  border: 1px solid #ffc107;
  transition: 0.4s;
}
.contact-us-form .contact-form button svg {
  transition: 0.4s;
}
.contact-us-form .contact-form button svg path {
  fill: #1a1a1a;
}
.contact-us-form .contact-form button:hover {
  background-color: #1a1a1a;
  border: 1px solid #FFC107;
  color: #FFC107;
}
.contact-us-form .contact-form button:hover svg {
  transform: translateX(5px);
}
.contact-us-form .contact-form button:hover svg path {
  fill: #FFC107 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.tax-sercices-sec {
  margin: 50px 0px 200px 0px;
  padding-top: 50px;
}
.tax-sercices-sec .direct-tax-service .heding-sec {
  margin: 50px 0px;
}
.tax-sercices-sec .service-content h2 {
  font-size: 1rem;
  margin-bottom: 30px;
  font-weight: 600;
}
.tax-sercices-sec .service-content p, .tax-sercices-sec .service-content ol, .tax-sercices-sec .service-content li {
  font-weight: 400;
}
.tax-sercices-sec .service-content .tax-offers h2 {
  margin-top: 50px;
}
.tax-sercices-sec .service-content .tax-offers ol li {
  margin-bottom: 10px;
}
.tax-sercices-sec .service-content .tax-offers ul li {
  margin-bottom: 0px;
}
.tax-sercices-sec .faqs-sec .faqs-content {
  margin: 50px 0px;
}
.tax-sercices-sec .faqs-sec .faqs-content .accordion-button:not(.collapsed) {
  background-color: unset;
  border: unset;
}
.tax-sercices-sec .faqs-sec .faqs-content .accordion-button {
  font-weight: 600;
  padding: 22px;
}
.tax-sercices-sec .faqs-sec .faqs-content .accordion-button:focus {
  box-shadow: unset;
}
.tax-sercices-sec .faqs-sec .faqs-content .accordion-collapse {
  font-weight: 400;
}

.dropdown-menu {
  border: 0px !important;
  border-radius: 0px !important;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #1a1a1a !important;
}

@media only screen and (max-width: 576px) {
  body {
    overflow-x: hidden;
  }
  .home-banner .text-sec .heading {
    font-size: 2rem;
    margin: 0px 15px 0px 15px;
    line-height: 44px;
  }
  .mini-header {
    display: none;
  }
  .navbar-toggler {
    background-color: #1a1a1a;
  }
  .button-container {
    width: 100%;
  }
  .home-banner .text-sec .para-text {
    margin: 0px 15px 0px 15px;
  }
  .home-banner .text-sec {
    margin-top: 50px;
  }
  .home-banner .text-sec .g-review {
    padding: 30px 10px;
    height: 150px;
  }
  .home-banner .text-sec .g-review .google-review-text {
    gap: 0.3rem;
  }
  .static-numbers-sec, .services-sec, .why-choose-moks-sec, .work-process-sec, .client-reviews, .working-area, .about-us, .about-main-sec-1, .about-main-sec-2, .our-teams, .contact-us, .contact-us-right, .tax-services, .direct-tax-service, .indirect-tax-service, .faqs-sec {
    padding: 50px 0px;
  }
  .static-numbers-sec .heding-sec .main-heading, .services-sec .heding-sec .main-heading, .why-choose-moks-sec .heding-sec .main-heading, .work-process-sec .heding-sec .main-heading, .client-reviews .heding-sec .main-heading, .working-area .heding-sec .main-heading, .about-us .heding-sec .main-heading, .about-main-sec-1 .heding-sec .main-heading, .about-main-sec-2 .heding-sec .main-heading, .our-teams .heding-sec .main-heading, .contact-us .heding-sec .main-heading, .contact-us-right .heding-sec .main-heading, .tax-services .heding-sec .main-heading, .direct-tax-service .heding-sec .main-heading, .indirect-tax-service .heding-sec .main-heading, .faqs-sec .heding-sec .main-heading {
    font-size: 1.8rem;
    line-height: 35px;
    width: 100%;
  }
  .static-numbers-sec .heding-sec .small-heading, .services-sec .heding-sec .small-heading, .why-choose-moks-sec .heding-sec .small-heading, .work-process-sec .heding-sec .small-heading, .client-reviews .heding-sec .small-heading, .working-area .heding-sec .small-heading, .about-us .heding-sec .small-heading, .about-main-sec-1 .heding-sec .small-heading, .about-main-sec-2 .heding-sec .small-heading, .our-teams .heding-sec .small-heading, .contact-us .heding-sec .small-heading, .contact-us-right .heding-sec .small-heading, .tax-services .heding-sec .small-heading, .direct-tax-service .heding-sec .small-heading, .indirect-tax-service .heding-sec .small-heading, .faqs-sec .heding-sec .small-heading {
    font-size: 1rem;
  }
  .static-details {
    padding: 20px;
  }
  .static-details .static-content {
    margin-bottom: 30px;
  }
  .static-details .static-content .static-content-heading {
    margin-bottom: 10px !important;
  }
  .services-sec .row, .why-choose-moks-sec .row {
    gap: 1rem;
  }
  .services-sec .row .mt-4, .why-choose-moks-sec .row .mt-4 {
    margin-top: 0rem !important;
  }
  .why-choose-moks-sec .row, .work-process-sec .row {
    flex-direction: column-reverse;
  }
  .work-process-sec .prcess-cards {
    margin: 5px 0px 5px 15px;
    padding: 30px 0px 30px 60px;
  }
  .prcess-cards.w-75 {
    width: 100% !important;
  }
  .client-reviews {
    display: none;
  }
  .work-process-sec .prcess-cards .steps-cards .steps-content-heading {
    font-size: 1.2rem;
    line-height: 25px;
    margin: 0px;
  }
  .work-process-sec .prcess-cards .steps-cards .steps-number {
    font-size: 5rem;
  }
  .footer .black-sqaure {
    position: unset;
    transform: unset;
  }
  .footer .subscribe {
    padding: 30px 0px;
    text-align: left;
  }
  .mini-footer .mini-footer-inner p {
    margin-bottom: 10px !important;
  }
  .mini-footer .mini-footer-inner {
    padding: 10px;
  }
  .footer .links ul li {
    margin-bottom: 10px;
  }
  .footer .black-sqaure {
    padding: 30px;
    width: 100%;
  }
  .our-teams .teams-card {
    margin: 50px 0px;
  }
  .team-card-2, .team-card-3 {
    transform: unset !important;
  }
  .about-us {
    height: 200px;
  }
  .about-main-sec-1 img {
    margin-bottom: 30px !important;
  }
  .our-teams .modal .modal-inner::before {
    content: "";
    height: 27%;
  }
  .team-profile-details .sec-heading {
    margin-top: 120px;
  }
  .our-teams .modal .modal-body {
    padding: 1rem;
  }
  .contact-us-form .contact-us-right {
    padding: 20px 20px 20px 20px;
  }
  .contact-us-form .contact-us-right .contact-us-right-details .call-discuss-keys .call-discuss-keys-points svg {
    display: none;
  }
  .tax-sercices-sec {
    margin: 0px;
    padding-top: 0px;
  }
  .working-area {
    margin-bottom: 0px;
  }
}/*# sourceMappingURL=index.css.map */